import { ConsentApiClient } from "./account-api-client";
import {
  ConsentRecord,
  LegalText,
} from "customer-consent-ui/consent-component/src/ts/component";

export const elName = "p-ewe-component";

const template = document.createElement("template");
template.innerHTML = `
<style>

* {
-webkit-font-smoothing:antialiased;
}

h1, h2, h3, p, span, ol, ul, li {
  margin:0;
}

.root {
    font-size: 15px;
    font-family: var(--font-stack-Open-Sans);
    font-weight: 600;
    line-height: 21px;
    color: var(--gray-midnight);
    counter-reset: h2counter;
}

h2 {
    font-size: 15px;
    font-family: var(--font-stack-Open-Sans);
    font-weight: 700;
    line-height: 21px;
    color: var(--gray-slate);
}

h2:before {
    content: "(" counter(h2counter) ") ";
    counter-increment: h2counter;
}

ul {
    list-style-type:disc;
    padding-left:1.5rem;
}

strong {
    font-weight:700 !important;
}

ol {
    padding-left:1.5rem;
}

.message {
    margin-bottom: 24px;
}

.ewe-short-text {
    margin-top: 8px;
}

.ewe-expand {
    margin-top: 4px;
    color: var(--penny-red);
}

.ewe-expand::after {
    width: 20px;
    height: 20px;
    content: "";
    mask: var(--icon-chevron-url);
    -webkit-mask: var(--icon-chevron-url);
    background-color: var(--penny-red);
    display: inline-block;
    mask-size: cover;
    vertical-align: -25%;
}

.ewe-long-text {
    margin-top: 16px;
}

.no-list-type{
    list-style-type:none;
}

.ewe-long-text > .list-wrap {
    display: flex;
    flex-direction:column;
    gap:15px;
}

.ewe-consented-at {
    margin-top: 8px;
    font-family: var(--font-stack-Open-Sans);
    font-size: 11px;
    font-weight: 600;
}

details > summary {
    list-style-type: '';
    .ewe-expand::after {
        transform: rotate(90deg);
    }
}

details[open] > summary {
    list-style-type: '';
    .ewe-expand::after {
        transform: rotate(270deg);
    }
}

</style>
<link href="${(window as any)["common/generated/common-bundle.css.ftl"]}" rel="stylesheet"/>
<div class="root">
    <div>
        <!-- TODO probably needs aria-live-region, check with screen reader -->
        <div class="message error" hidden>
            Leider können wir deine Anfrage zur Zeit nicht bearbeiten.
            Bitte lade die Seite neu, oder versuch es später noch einmal.
        </div>
        <label class="label-with-checkbox-switch">
          <input type="checkbox" class="checkbox-switch">
        </label>
    </div>
    <div class="ewe-short-text"></div>
    <details>
        <summary>
            <div class="ewe-expand">
                Alle Details
            </div>
        </summary>
        <div class="ewe-long-text"></div>
    </details>
    <div class="ewe-consented-at"></div>
</div>
`;

export class EweComponent extends HTMLElement {
  private titleLabel: HTMLLabelElement;
  private consentCheckbox: HTMLInputElement;
  private shortText: HTMLDivElement;
  private consentedAtText: HTMLDivElement;
  private longText: HTMLDivElement;
  private _consent?: ConsentRecord;
  private _apiClient: ConsentApiClient;
  private error: HTMLDivElement;

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
  }

  connectedCallback() {
    this.classList.add(elName);
    this.titleLabel = this.shadowRoot.querySelector("label");
    this.consentCheckbox = this.shadowRoot.querySelector(".checkbox-switch");
    this.shortText = this.shadowRoot.querySelector(".ewe-short-text");
    this.consentedAtText = this.shadowRoot.querySelector(".ewe-consented-at");
    this.longText = this.shadowRoot.querySelector(".ewe-long-text");
    this.error = this.shadowRoot.querySelector(".message.error");

    const style = this.shadowRoot.querySelector(".ewe-expand")["style"];
    const iconUrl = `${(window as any).resourceUrl}/img/icon-chevron.svg`;
    style.setProperty("--icon-chevron-url", `url(${iconUrl})`);

    this.consentCheckbox.addEventListener("change", () => {
      const newValue = this.consentCheckbox.checked;
      this.error.hidden = true;
      this._apiClient
        .putConsent(this.titleLabel.textContent, {
          ...this._consent,
          ...{ state: newValue ? "ACCEPTED" : "REJECTED" },
        })
        .then(() => {
          this.consentedAtText.textContent = this.statusChangedText(
            {
              ...this._consent,
              ...{ lastChangeAt: new Date().toISOString() }
            },
            newValue
          );
        })
        .catch((e) => {
          console.error(e);
          this.consentCheckbox.checked = !newValue;
          this.error.hidden = false;
        });
    });
  }

  set apiClient(client: ConsentApiClient) {
    this._apiClient = client;
  }

  set consent(value: ConsentRecord) {
    this._consent = value;
    this.consentCheckbox.checked = this.agreed;
    this.consentedAtText.textContent = this.statusChangedText(this._consent, this.agreed);

    this.titleLabel.prepend(this._consent.consentDetails.title);
    this.shortText.textContent = this._consent.consentDetails.description;

    this.longText.innerHTML = ``;
    this.longText.append(
      renderLegalText(this._consent.consentDetails.legalText),
    );
  }

  get consent() {
    return this._consent;
  }

  get agreed() {
    return this._consent && this._consent.state === "ACCEPTED";
  }

  statusChangedText(consent: ConsentRecord, agreed: boolean) {
    if (consent.lastChangeAt) {
      const formatted = isoDateToDe(consent.lastChangeAt);
      if (agreed) {
        return `Eingewilligt am ${formatted}`;
      }
      return `Abgelehnt am ${formatted}`;
    }
    return "";
  }
}

customElements.define(elName, EweComponent);

function isoDateToDe(isoDate: string): string {
  const date = new Date(isoDate);
  const format = new Intl.DateTimeFormat("de-DE", {
    dateStyle: "medium",
  });
  return format.format(date);
}
function renderLegalText(text: LegalText): HTMLElement {
  // TODO decide if we care about potential stack overflow via recursion
  if (Array.isArray(text)) {
    const div = document.createElement("div");
    div.classList.add("list-wrap");
    const wrapped = text.map(renderLegalText);
    div.append(...wrapped);
    return div;
  }
  if (text.type === "bold") {
    const el = document.createElement("strong");
    el.textContent = text.content;
    return el;
  }
  if (text.type === "text") {
    const el = document.createElement("span");
    el.textContent = text.content;
    return el;
  }
  if (text.type === "orderedList") {
    const el = document.createElement("ol");
    for (const child of text.content) {
      const li = document.createElement("li");
      const content = renderLegalText(child);
      if (content.tagName === "OL") {
        li.classList.add("no-list-type");
      }
      li.append(content);
      el.append(li);
    }
    return el;
  }
  if (text.type === "unorderedList") {
    const el = document.createElement("ul");
    for (const child of text.content) {
      const li = document.createElement("li");
      const content = renderLegalText(child);
      if (content.tagName === "UL") {
        li.classList.add("no-list-type");
      }
      li.append(content);
      el.append(li);
    }
    return el;
  }
  if (text.type === "paragraph") {
    const el = document.createElement("p");
    el.append(renderLegalText(text.content));
    return el;
  }
  if (text.type === "heading") {
    const el = document.createElement("h2");
    el.textContent = text.content;
    return el;
  }
  if (text.type == "link") {
    const el = document.createElement("a");
    el.href = text.href;
    el.textContent = text.content;
    return el;
  }
}
